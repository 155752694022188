<template>
    <div class="change-company" style="">
        <NoButtonModal :open="true" id="company_dropdown_modal">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Change Company</span>
                <b-tooltip target="close" placement="bottom" title="Close"></b-tooltip>
                <div
                    id="close"
                    class="material-symbols-outlined btn-popup-close-preview ms-2 rounded-circle"
                    role="button"
                    style="top: 10px"
                    @click="$emit('close')"
                >
                    close
                </div>
            </div>
            <template v-if="!loading">
                <div class="mb-4 d-flex justify-content-center w-100 mt-5">
                    <label for="company" class="add-new-app-label w-20 my-2">Company List</label>
                    <Multiselect
                        id="company"
                        track-by="id"
                        label="company_name"
                        class="position-relative w-60"
                        placeholder="Search Company..."
                        v-model="companyData"
                        :options="companyList"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @change="handleCompanyChange($event)"
                        :disabled="showLoader"
                    />
                </div>
                <div class="align-right" style="bottom: 10%; position: absolute; right: 5%">
                    <button
                        :class="`margin-0 proceed-button`"
                        :disabled="!companyData.id"
                        @click="submit"
                        :style="{ cursor: !companyData.id ? 'not-allowed' : '' }"
                    >
                        Proceed with Company
                        <span class="material-icons-outlined" style="color: white" v-if="!showLoader"
                            >arrow_forward</span
                        >
                        <Loading
                            :outerHeight="'21px'"
                            :outerWidth="'21px'"
                            :height="'21px'"
                            :width="'21px'"
                            :color="'white transparent transparent transparent'"
                            :borderWidth="'3px'"
                            v-else
                        />
                    </button>
                </div>
            </template>
            <div
                v-if="loading"
                class="my-4 h-50 d-flex flex-column justify-content-center align-items-center loader-main"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import Loading from '@/components/general/loading/loading';
    import { companies, auth } from '@/util/apiRequests';

    export default {
        name: 'ChangeCompany',
        data() {
            return {
                companyData: '',
                companyList: [],
                loading: true,
                showLoader: false,
            };
        },
        components: { NoButtonModal, Loading },
        methods: {
            handleCompanyChange(event) {
                this.companyList.forEach((company) => {
                    if (company.company_name == event.target.value) {
                        this.companyData = company;
                    }
                });
            },
            async submit() {
                try {
                    this.showLoader = true;
                    const res = await this.$api.post(auth.changeCompany(), {
                        email: this.$store.state?.user?.email,
                        companyId: this.companyData.id,
                    });
                    if (res.status == 200) {
                        let user = { ...res.data.user };
                        this.$store.commit('persistUser', user);
                        this.$store.commit('persistBuildId', res.data.buildId);
                        this.$toasted.success('Company changed successfully');
                    }
                } catch (err) {
                    this.$toasted.error('Failed to change company');
                } finally {
                    this.showLoader = false;
                    this.$emit('onCompanyChange');
                }
            },
            async getAllCompanies() {
                try {
                    const endpoint = companies.getAllCompanies();
                    const response = await this.$api.get(endpoint);
                    this.companyList = response?.data?.info?.filter(
                        (item) => item.id != this.$store.state?.user?.company_id
                    );
                    this.$store.commit('setDefaultCompanyId', response.data.info[0].id);
                } catch (err) {
                    this.$toasted.error('Failed to fetch Company list');
                }
            },
        },
        async created() {
            await this.getAllCompanies();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .proceed-button {
        box-shadow: inset 0 0 0 1px var(--button-color);
        background-color: var(--button-color) !important;
        color: white !important;
        border: none;
        border-radius: 5px;
        padding: 9.5px 20px;
        gap: 10px;
        display: flex;
    }

    .disabled {
        cursor: not-allowed;
    }
</style>

<style>
    .change-company .modal__content {
        padding: 0;
        border-radius: 10px;
        height: 50vh;
    }
</style>
