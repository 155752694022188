<template>
    <div class="bg-color rounded-5 p-5">
        <div class="w-100 rounded-4">
            <div
                class="d-flex justify-content-between"
                style="background: rgb(241, 247, 255); border-radius: 27px 27px 0 0"
            >
                <h1 class="px-5 py-3 page-heading">Company List</h1>
                <div class="d-flex justify-content-center">
                    <button
                        class="text-button no-bg weight-600"
                        style="margin-right: 10px; background: transparent"
                        @click="showChangeCompanyModal = true"
                    >
                        <span class="text-link margin-0"> Change company </span>
                    </button>
                    <!-- <button class="text-button no-bg weight-600" style="margin-right: 10px; background: transparent">
                    <router-link class="text-link margin-0" :to="{ name: 'AddNewCompany' }">
                        <img src="@/assets/px/add.png" alt="" style="height: 20px" /> Create new company
                    </router-link>
                </button> -->
                </div>
            </div>
            <div class="w-100 bg-white" v-if="!companyList">
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                    <h4>Fetching information...</h4>
                </div>
            </div>
            <div v-else-if="companyList.length > 0" class="profile-listing-body pb-2 w-100">
                <b-table
                    id="my-table"
                    :items="companyList.slice().reverse()"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    bordered
                    responsive
                >
                    <template #cell(logo)="row" style="padding-left: 45px !important">
                        <img :src="row.item.logoUrl" alt="" style="max-height: 50px; max-width: 100px" />
                    </template>
                    <template #cell(company_name)="row">
                        <div style="word-wrap: break-word">
                            {{ row.item.company_name }}
                        </div>
                    </template>
                    <template #cell(company_email)="row">
                        <div style="word-wrap: break-word; text-transform: lowercase">
                            {{ row.item.company_email }}
                        </div>
                    </template>
                    <template #cell(action)="row">
                        <b-tooltip :target="Number(row.item.id) + 'view'" triggers="hover" title="View" />
                        <b-tooltip :target="Number(row.item.id) + 'edit'" triggers="hover" title="Edit" />
                        <img
                            class="px-2"
                            style="height: 15px"
                            src="@/assets/px/eye.svg"
                            @click="companyDetailsModalOpen(row.item)"
                            :id="Number(row.item.id) + 'view'"
                        />
                        <img
                            class="px-2"
                            style="height: 15px"
                            src="@/assets/px/edit-icon.png"
                            :id="Number(row.item.id) + 'edit'"
                            @click="editCompany(row.item.id)"
                        />
                        <!-- <Toggle
                            class="toggle-switch"
                            :id="row.item.id"
                            :value="row.item.active"
                            :labelClass="'switch'"
                            :spanClass="'slider'"
                            @input="handleActiveStatus(item)"
                        /> -->
                    </template>
                </b-table>
                <div class="b-table-pagination">
                    <div class="pagination-count-per-page">
                        <div class="pagination-number-per-page">
                            <div>
                                <span>Per page:</span>
                            </div>
                            <div>
                                <select style="" @change="changeCountPerPage" :value="perPage">
                                    <option v-for="item in perPageOptions" :key="item" :value="item">
                                        {{ item }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <b-pagination
                        align="right"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        aria-controls="my-table"
                        first-text="First"
                        prev-text="Prev"
                        next-text="Next"
                        last-text="Last"
                    ></b-pagination>
                </div>
            </div>
            <div v-else class="w-100 bg-white">
                <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                    <h4>No items created</h4>
                </div>
            </div>
            <!-- <div class="profile-delete-modal">
            <AlertDeleteDashboardTemplate
                v-if="showDeleteAlert"
                :open="showDeleteAlert"
                @cancel="showDeleteAlert = false"
                @handleDelete="handleDelete"
               >
            </AlertDeleteDashboardTemplate>
            </div> -->
            <CompanyDetailsModal
                v-if="isCompanyDetailsModalOpen"
                :open="isCompanyDetailsModalOpen"
                :companyDetails="companyDetails"
                @close="closeCompanyDetailsModal"
            />
            <ChangeCompanyModal
                v-if="showChangeCompanyModal"
                @close="showChangeCompanyModal = false"
                @onCompanyChange="onCompanyChange"
            />
        </div>
        <Loading v-if="loading" />
    </div>
</template>

<script>
    import CompanyDetailsModal from './CompanyDetailsModal.vue';
    import ChangeCompanyModal from './ChangeCompanyModal.vue';
    import { companies } from '../../util/apiRequests';
    import { tryGetFilter } from '@/util/tryGetFilter';
    import Loading from '@/components/general/loading/loading.vue';

    export default {
        name: 'CompanyList',
        components: {
            CompanyDetailsModal,
            ChangeCompanyModal,
            Loading,
        },
        data() {
            return {
                companyList: null,
                showDeleteAlert: false,
                indexTodelete: null,
                isCompanyDetailsModalOpen: false,
                companyDetails: {},
                companyLogoList: [],
                fields: [
                    { key: 'logo', label: 'Logo', thStyle: { 'padding-left': '40px !important' } },
                    { key: 'company_name', label: 'Company Name' },
                    { key: 'company_email', label: 'Company Email' },
                    { key: 'phone', label: 'Phone Number' },
                    { key: 'city', label: 'City' },
                    { key: 'action', label: 'Action' },
                ],

                // b-table properties
                storeKey: 'Dashboards',
                perPageOptions: [5, 10, 20, 50, 100],
                currentPage: 1,
                perPage: 10,
                totalRows: null,
                loading: true,
                showChangeCompanyModal: false,
            };
        },
        computed: {
            rows() {
                return this.totalRows;
            },
        },
        methods: {
            async getCompanyList() {
                try {
                    const endpoint = companies.getAllCompanies();
                    // const endpoint = companies.getAllCompanies(0);
                    const response = await this.$api.get(endpoint);
                    const data = response.data.info;
                    this.companyList = data;
                    this.totalRows = data.length;
                } catch (err) {
                    this.$toasted.error('Failed to fetch company list');
                } finally {
                    this.loading = false;
                }
            },
            async handleActiveStatus(item) {
                try {
                    const endpoint = companies.setActiveStatusCompany(item.id);

                    const requestData = {
                        activestatus: !item.active,
                    };

                    let response = await this.$api.post(endpoint, requestData);

                    if (response.status == 200) {
                        this.companyList.forEach((company, idx, tempCompanyList) => {
                            if (company.id == item.id) {
                                tempCompanyList[idx].active = !item.active;
                            }
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            },
            closeCompanyDetailsModal() {
                document.body.style.overflow = 'auto';
                this.isCompanyDetailsModalOpen = false;
                this.companyDetails = {};
            },
            companyDetailsModalOpen(item) {
                this.companyDetails = item;
                this.isCompanyDetailsModalOpen = true;
                document.body.style.overflow = 'hidden';
            },
            editCompany(id) {
                this.$router.push({ name: 'CompanyUpdate', params: { companyId: id } });
            },
            changeCountPerPage({ target: { value } }) {
                this.perPage = Number(value);
            },
            savedPagination() {
                const previousPagination = tryGetFilter(this.$store, this.storeKey);
                this.currentPage = previousPagination.currentPage ?? 1;
                this.perPage = previousPagination.perPage ?? 10;
            },
            async onCompanyChange() {
                this.showChangeCompanyModal = false;
                this.loading = true;
                await this.getCompanyList();
            },
        },
        async created() {
            this.savedPagination();
            await this.getCompanyList();
        },
        watch: {
            currentPage: function (val) {
                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        currentPage: Number(val),
                        perPage: Number(this.perPage),
                    },
                });
            },
            perPage: function (val) {
                this.$store.commit('filters/updateFilter', {
                    stateKey: this.storeKey,
                    criteria: {
                        perPage: Number(val),
                        currentPage: Number(this.currentPage),
                    },
                });
            },
        },
    };
</script>

<style scoped>
    .bg-color {
        background-color: rgba(255, 255, 255, 0.9);
    }
</style>

<style scoped>
    .text-button a,
    .text-button a:hover,
    .text-button:hover {
        background-color: #5057c3;
        color: white;
        padding: 10px;
        border-radius: 10px;
    }

    .text-button span,
    .text-button span:hover {
        background-color: #5057c3;
        color: white;
        padding: 10px;
        border-radius: 10px;
    }
</style>
